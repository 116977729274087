import React from "react"
import { Article } from "../components/Article/Article"
import { Comments } from "../components/Comments/Comments"
import { Layout } from "../components/Layout/Layout"
import { MobileHeader } from "../components/MobileHeader/MobileHeader"
import { Seo } from "../components/Seo/Seo"
import { ArticleEntity } from "../entities/ArticleEntity"
import { buildFaq } from "../utils/buildFaq"

type Props = {
  pageContext: {
    article: ArticleEntity
    aside: any
    language: string
    configuration: any
    header: any
    footer: any
    drawer: any
    cookies: any
    theme: string
    canonicals: {
      lang: string
      url: string
      isDefault: boolean
    }[]
  }
}

const ArticleTemplate: React.FC<Props> = props => {
  return (
    <>
      <Seo
        title={props.pageContext.article.meta?.title}
        description={props.pageContext.article.meta?.description}
        domain={props.pageContext.configuration.domain}
        lang={props.pageContext.language}
        article={props.pageContext.article}
        image={props.pageContext.article.illustration?.src?.publicURL}
        faq={buildFaq(props.pageContext.article.content)}
        indexable={props.pageContext.article.meta?.indexable}
        langUrls={props.pageContext.canonicals}
      />

      <Layout
        theme={props.pageContext.theme}
        header={props.pageContext.header}
        drawer={props.pageContext.drawer}
        aside={props.pageContext.aside}
        language={props.pageContext.language}
      >
        <MobileHeader
          id={props.pageContext.article.id}
          title={props.pageContext.article.meta?.title}
          description={props.pageContext.article.meta?.description}
        />
        <Article {...props.pageContext.article} />
        <Comments id={props.pageContext.article.id} />
      </Layout>
    </>
  )
}

export default ArticleTemplate
